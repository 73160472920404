@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700,800);
@import url(https://fonts.googleapis.com/css2?family=Creepster&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap);
@import url(https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Mountains+of+Christmas:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lora:wght@400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Dela+Gothic+One&display=swap);
@font-face {
    font-family: 'FeniceStd';
    src: local('FeniceStd'), url(/static/media/FeniceStd-Regular.66e7d1ec.otf) format('truetype');
}
  
@font-face {
    font-family: 'FeniceStd';
    src: local('FeniceStd'), url(/static/media/FeniceStd-Oblique.b3fc3cb1.otf) format('truetype');
    font-style: oblique;
}
  
@font-face {
    font-family: 'FeniceStd';
    src: local('FeniceStd'), url(/static/media/FeniceStd-Bold.602d4e19.otf) format('truetype');
    font-weight: bold;
}
@font-face {
    font-family: 'NeonTubes2';
    src: local('NeonTubes2'), url(/static/media/NeonTubes2.f01f7e70.otf) format('truetype');
}
@font-face {
    font-family: 'CryUncial';
    src: local('CRYUNCIAL-OAOO'), url(/static/media/CRYUNCIAL-OAOO.5632d741.TTF) format('truetype');
}
@font-face {
    font-family: 'FuturaPT-Bold';
    src: local('FuturaPT-Bold'), url(/static/media/FuturaPT-Bold.30a810fa.ttf) format('truetype');
}
@font-face {
    font-family: 'FuturaPT-Med';
    src: local('FuturaPT-Medium'), url(/static/media/FuturaPT-Medium.4ab635fb.ttf) format('truetype');
}
@font-face {
    font-family: 'FuturaPT-Book';
    src: local('FuturaPT-Book'), url(/static/media/FuturaPT-Book.16ebcf48.ttf) format('truetype');
}
@font-face {
    font-family: 'FuturaPT-Light';
    src: local('FuturaPT-Light'), url(/static/media/FuturaPT-Light.ba102592.ttf) format('truetype');
}

* {
    box-sizing: border-box;
    border: none;
    outline: none;
}

html {
    overflow: hidden;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif !important;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}
/* disable selecting (more native) */
div { 
 -webkit-user-select: none; /* Safari 3.1+ */ /* Firefox 2+ */ /* IE 10+ */
 user-select: none; /* Standard syntax */
 }
p {
    margin: 0;
}
button:focus {
    outline: none;
}

.footerWeatherGroup {
    margin-left: 2vw;
}

.faded {
    opacity: 0.3;
}

.tourSidebar {
    position: absolute;
    z-index: 100;
    width: 9.25vw;
    height: 75vh;
    top: 11.5vh;
    right: 1vw;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    border-radius: 3vw;
}

.tourQR {
    position: absolute;
    z-index: 5;
    bottom: 12.5vh;
    right: 1vw;
    width: auto;
    height: 23vh;
    border-radius: 2vw;
}

.tourGuideModal {
    border: none;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    margin: auto;
    margin-top: 12.5vh;
    height: 75vh;
    width: 50vw;
    z-index: 10;
    -webkit-flex-direction: column;
            flex-direction: column;
    background-color: #ffffff;
    border-radius: 5vh;
    padding: 3vw;
}

.guideComponent {
    display: -webkit-flex;
    display: flex;
    margin-left: 3vw;
    margin-right: 3vw;
}

.guideTitle {
    display: -webkit-flex;
    display: flex;
    margin-left: 3vw;
    margin-right: 5vw;
}

.guideButton {
    top: 10vh;
    right: 25.5vw;
    position: fixed;
    font-size: 45pt;
    background-color: rgba(155, 155, 155, 0)
}

.guideImage {
    object-fit: cover;
    width: 30vw;
    height: 50%;
    -webkit-align-self: center;
            align-self: center;
}

.enterButtonWrapper {
    overflow: hidden;
    border: none;
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    -webkit-align-self: center;
            align-self: center;
    z-index: 10;
}

.qrModal {
    position: fixed;
    background-color: #000000;
    top: 16vh;
    left: 15vw;
    height: 65vh;
    width: 70vw;
    display: -webkit-flex;
    display: flex;
    box-shadow: 5px 5px 10px 1px black;
}

.qrModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    color: #000000;
    overflow-x: hidden;
    overflow-y: hidden;
    border: none;
    z-index: 12;
}

.qrModalCloseButton {
    position: relative;
    font-size: 8vh;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: inherit;
    color: white;
    left: 92%;
    top: 0;
    font-style: italic;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5), -2px -2px 4px rgba(0, 0, 0, 0.5);
}

.driverqrWrapper {
    height: 28vh;
    width: 30vw;
    position: relative;
    top: 16vh;
    left: 10vw;
    z-index: 10;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: stretch;
            align-items: stretch;
    -webkit-flex-direction: column;
            flex-direction: column;
    text-align: justify;
}

/* .qrWrapper {
    height: 25vh;
    width: 20vw;
    position: relative;
    top: 17vh;
    left: 15vw;
} */

/* .qrTitle {
    color: #00AADC;
    position: relative;
    font-family: sans-serif;
    font-size: 4vh;
} */

.driverqrTitle {
    color: #00AADC;
    position: relative;
    font-family: sans-serif;
    font-size: 3.5vh;
    margin: 0 0 3vh 0;
}

.signInMessage {
    font-size: 3vh;
    position: relative;
    color: #c4c4c4;
    font-weight: bold;
    font-family: sans-serif;
    text-align: justify;
}

.qrBackground {
    background-color: white;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    top: 16%;
    left: 10%;
    z-index: 10;
}

.qrCode {
    padding: 10%;
}

.adModalOverlay, .videoModalOverlay {
    height: 100vh;
    width: 100vw;
}

.adModal, .videoModal {
    overflow-x: hidden;
    overflow-y: hidden;
    z-index: 10;
    border: none;
    display: -webkit-flex;
    display: flex;
    -webkit-align-self: center;
            align-self: center;
    -webkit-align-items: center;
            align-items: center;
    background-color: black;
    z-index: 10000000;
    height: 100vh;
    width: 100vw;
}

.adModalOverlay, .videoModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    color: #000000;
    overflow-x: hidden;
    overflow-y: hidden;
    border: none;
    z-index: 10;
}

.adModalWrapper, .videoModalWrapper {
    overflow: hidden;
    border: none;
    text-align: center;
    display: -webkit-flex;
    display: flex;
    border: none;
    z-index: 10;
}

.videoModalCloseButton {
    position: fixed;
    background-color: rgba(0, 0, 0, 0);
    color: #ffffff;
    font-size: 10vh;
    text-align: center;
    top: 0vh;
    right: 0;
    left: 94vw;
    width: 8vh;
    height: 8vh;
    margin-bottom: -8vh;
    border: none;
    z-index: 13;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5), -2px -2px 4px rgba(0, 0, 0, 0.5);
}

.videoPlayer, .adVideoPlayer {
    display: -webkit-flex;
    display: flex;
    -webkit-align-self: center;
            align-self: center;
    text-align: center;
    border: none;
}

.videoTileTitle {
    text-align: center;
    z-index: 10;
    border: none;
    font-size: 1.5em;
    padding-left: 3vw;
    padding-right: 3vw;
    margin-top: -45vh;
    text-shadow: 0.2vh 0.2vh 3vh black, -0.2vh -0.2vh 3vh black;
}

.carousel {
    z-index: 1;
    border: none;
}

.dropdownWrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    text-align: center;
    border: none;
    outline: none;
    z-index: 5;
}

.dropdownModal {
    position: relative;
    top: 10vh;
    left: 15vw;
    width: 20vw;
    z-index: 15;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-flex-direction: column;
            flex-direction: column;
    border-bottom-right-radius: 3vh;
    border-top-right-radius: 3vh;
    border-left-width: 4px;
    border-left-style: solid;
}

.dropdownModalOverlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    position: fixed;
    background-color: rgba(0, 0, 0, 0);
    z-index: 15;
}

.dropdownItemText {
    font-size: 2.5vh;
    text-align: left;
}

.dropdownItem {
    z-index: 20;
    height: 8vh;
    margin-left: 2vw;
    width: 20vw;
    display: -webkit-flex;
    display: flex;
    -webkit-align-self: flex-start;
            align-self: flex-start;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    border-left: 5px solid inherit;
    border-top-right-radius: 3vh;
    border-bottom-right-radius: 3vh;
    border-bottom-left-radius: 0vh;
}

.dropdownButton {
    text-align: center;
    border: none;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    height: 10%;
    background-color: rgba(0, 0, 0, 0);
    border-radius: 1.5vh;
}

.dropdownPrompt {
    font-size: 2.5vh;
}

.userDivider {
    font-size: 3.5vh;
    margin-left: 2vw;
    margin-right: 2vw;
}


.articleFrame {
    position: fixed;
    left: 0;
    width: 100vw;
    height: 100%;
    overflow: hidden;
}

.imageTile {
    background-size: cover;
    display: -webkit-flex;
    display: flex;
    -webkit-align-self: center;
            align-self: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    text-transform: uppercase;
    text-align: center;
    color: white;
    text-decoration: none;
}

.newsTile {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    text-transform: uppercase;
    text-align: center;
    overflow-y: hidden;
}

.newsTileSlider {
    display: -webkit-flex;
    display: flex;
    height: 62vh;
    width: 28vw;
    -webkit-align-self: center;
            align-self: center;
    -webkit-align-items: stretch;
            align-items: stretch;
    -webkit-justify-content: center;
            justify-content: center;
    text-align: center;
    overflow: hidden;
    border-bottom-left-radius: 2.5vh;
    border-bottom-right-radius: 2.5vh;
}

.newsObject {
    height: 62vh;
    width: 92%;
    border-bottom-left-radius: 2.5vh;
    border-bottom-right-radius: 2.5vh;
    text-align: center;
    text-decoration: none;
    overflow-y: hidden;
}

.newsTitle {
    font-size: 2.2vh;
    margin-top: 2vh;
    height: 9vh;
    width: inherit;
    text-align: center;
    text-overflow: ellipsis;
    text-transform: uppercase;
}

.newsImage {
    height: 23vh;
    width: 25vw;
    object-fit: cover;
}

.newsDescription {
    text-align: center;
    text-transform: none;
    font-size: 2.2vh;
    padding-left: 1vw;
    padding-right: 1vw;
    margin-left: 5%;
    margin-right: 5%;
    height: 16vh;
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: none;
}

.newsFooter {
    font-size: 1.75vh;
    overflow-y: hidden;
    text-overflow: ellipsis;
    height: 2.5vh;
    margin-bottom: 2vh;
    width: 100%;
}

/*newsOverlay is used for fixing a bug that freezes the news slider during mobile scroll.*/
.newsOverlay {
    position: relative;
    background-color: transparent;
}

.newsOverlay:after {
    position: absolute;
    content:"";
    top:0;
    left:0;
    width:100%;
    height:100%;
    opacity:0;
}

.aboutDriverTab {
    top: 50vh;
    left: 0vw;
    position: fixed;
    height: 10vh;
    width: 10vw;
    border-top-right-radius: 2vh;
    border-bottom-right-radius: 2vh;
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    z-index: 8;
}

.driverButtonText {
    font-size: 2.5vh;
    padding: 0 1vw 0 1vw;
    margin: 0 1.2vw 0 1vw;
}

.commitHash {
    margin: 0 0 0 0;
    text-align: right;
}

.welcomeCommitHash {
    position: absolute;
    bottom: 2vh;
    right: 2vw;
    height: 3vh;
    width: 9vw;
    margin: 0 0 0 0;
    text-align: right;
    /* background-color: rgba(254, 254, 254, 0.35); */
    color: black;
}

.qrCommitHash {
    position: absolute;
    bottom: 2vh;
    right: 2vw;
    height: 3vh;
    width: 9vw;
    margin: 0 0 0 0;
    text-align: right;
    /* background-color: rgba(254, 254, 254, 0.35); */
    color: rgb(225, 255, 255);
    text-shadow: 5px 5px 15px rgba(0, 0, 0, 0.75), -5px -5px 15px rgba(0, 0, 0, 0.75);
}
.broadcastModal, .quietRideModal, .productModal {
  background-color: black;
  color: white;
  height: 30vh;
  width: 40vw;
  position: fixed;
  top: 35%;
  left: 31%;
  margin: 0%;
  box-shadow: 5px 5px 10px 1px black;
}

.broadcastCloseButton, .quietRideModalCloseButton, .productCloseButton {
  position: absolute;
  font-size: 8vh;
  background-color: inherit;
  color: white;
  left: 88%;
  top: 0;
  padding: 0%;
  font-style: italic;
  margin: 0%;
  display: inline;
}

.broadcastTitle, .quietRideTitle, .productTitle {
  color: #00AADC;
  position: absolute;
  font-family: sans-serif;
  font-size: 4vh;
  top: 8%;
  left: 22%;
  margin: 0%;
  display: inline;
}

.quietRideTitle {
  left: 35%;
}

.broadcastMessage, .quietRideMessage, .productMessage {
  margin: 0;
  position: absolute;
  font-family: sans-serif;
  font-size: 3vh;
  display: inline;
  width: -webkit-fill-available;
  height: 50%;
  margin-top: 20%;
  padding-left: 5%;
  padding-right: 5%;
  text-align: center;
}

.quietRideMessage {
  padding-left: 20%;
  padding-right: 20%;
}

.promoLogo {
  position: absolute;
  width: 15vw;
  bottom: 5vh;
  right: 1vw;
}
.incorrectText {
    color: red;
    font-size: 1.4vw;
    position: absolute;
    top: 7vw;
    left: 2vw;
}

.aboutDriverLogoutWrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: row;
            flex-direction: row;
    position: absolute;
    width: 30vw;
    height: 5vw;
    top: 2vw;
    left: 2vw;
}

.aboutDriverLogoutButton {
    height: 4vw;
    border-radius: 4px;
    text-align: center;
    font-size: 1.5vw;
    width: 8vw;
    position: relative;
    z-index: 10;
    color: white;
    margin: 0 1vw 0 0;
}

.aboutDriverWrapper {
    position: absolute;
    top: 0;
    left: 0;
    border: none;
    height: 100vh;
    width: 100vw;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.aboutDriverInfoPane {
    height: 100vh;
    width: 40vw;
    display: -webkit-flex;
    display: flex;
    -webkit-align-self: center;
            align-self: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    /* background-color: rgba(0, 0, 0, 0); */
}

.aboutDriverInfoBox {
    display: -webkit-flex;
    display: flex;
    margin-top: 5vh;
    margin-bottom: 5vh;
    -webkit-flex-direction: column;
            flex-direction: column;
    height: 25vh;
    width: 48vw;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    border-radius: 2vh;
}

.aboutDriverInfoHeader {
    text-align: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    height: 12vh;
    width: 48vw;
    padding-top: 1.5vh;
    padding-bottom: 1vh;
    font-size: 3.5vh;
    border-top-right-radius: 2vh;
    border-top-left-radius: 2vh;
    box-shadow: 2px 2px rgba(0, 0, 0, 0.1);
}

.aboutDriverInfoBody {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-self: center;
            align-self: center;
    height: 28vh;
    width: 48vw;
    padding-top: 1.5vh;
    padding-bottom: 1.5vh;
    padding-left: 2vw;
    padding-right: 2vw;
    font-size: 3vh;
    text-align: center;
    border-bottom-right-radius: 2vh;
    border-bottom-left-radius: 2vh;
    overflow: hidden;
    word-wrap: break-word;
    text-overflow: ellipsis;
}

.aboutDriverImage {
    object-fit: cover;
    object-position: center;
    display: -webkit-flex;
    display: flex;
    -webkit-align-self: center;
            align-self: center;
    height: 30vw;
    width: 30vw;
    border-radius: 50%;
}

.aboutDriverModalClose {
    position: absolute;
    background-color: rgba(0, 0, 0, 0);
    font-size: 6vw;
    text-align: center;
    top: 0;
    right: 2vw;
    border: none;
    z-index: 10;
}

.aboutDriverModal {
    background-color: rgba(0, 0, 0, 0);
}

