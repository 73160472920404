.broadcastModal, .quietRideModal, .productModal {
  background-color: black;
  color: white;
  height: 30vh;
  width: 40vw;
  position: fixed;
  top: 35%;
  left: 31%;
  margin: 0%;
  box-shadow: 5px 5px 10px 1px black;
}

.broadcastCloseButton, .quietRideModalCloseButton, .productCloseButton {
  position: absolute;
  font-size: 8vh;
  background-color: inherit;
  color: white;
  left: 88%;
  top: 0;
  padding: 0%;
  font-style: italic;
  margin: 0%;
  display: inline;
}

.broadcastTitle, .quietRideTitle, .productTitle {
  color: #00AADC;
  position: absolute;
  font-family: sans-serif;
  font-size: 4vh;
  top: 8%;
  left: 22%;
  margin: 0%;
  display: inline;
}

.quietRideTitle {
  left: 35%;
}

.broadcastMessage, .quietRideMessage, .productMessage {
  margin: 0;
  position: absolute;
  font-family: sans-serif;
  font-size: 3vh;
  display: inline;
  width: -webkit-fill-available;
  height: 50%;
  margin-top: 20%;
  padding-left: 5%;
  padding-right: 5%;
  text-align: center;
}

.quietRideMessage {
  padding-left: 20%;
  padding-right: 20%;
}

.promoLogo {
  position: absolute;
  width: 15vw;
  bottom: 5vh;
  right: 1vw;
}