.incorrectText {
    color: red;
    font-size: 1.4vw;
    position: absolute;
    top: 7vw;
    left: 2vw;
}

.aboutDriverLogoutWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    position: absolute;
    width: 30vw;
    height: 5vw;
    top: 2vw;
    left: 2vw;
}

.aboutDriverLogoutButton {
    height: 4vw;
    border-radius: 4px;
    text-align: center;
    font-size: 1.5vw;
    width: 8vw;
    position: relative;
    z-index: 10;
    color: white;
    margin: 0 1vw 0 0;
}

.aboutDriverWrapper {
    position: absolute;
    top: 0;
    left: 0;
    border: none;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.aboutDriverInfoPane {
    height: 100vh;
    width: 40vw;
    display: flex;
    align-self: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-color: rgba(0, 0, 0, 0); */
}

.aboutDriverInfoBox {
    display: flex;
    margin-top: 5vh;
    margin-bottom: 5vh;
    flex-direction: column;
    height: 25vh;
    width: 48vw;
    align-items: center;
    justify-content: center;
    border-radius: 2vh;
}

.aboutDriverInfoHeader {
    text-align: center;
    flex-wrap: wrap;
    height: 12vh;
    width: 48vw;
    padding-top: 1.5vh;
    padding-bottom: 1vh;
    font-size: 3.5vh;
    border-top-right-radius: 2vh;
    border-top-left-radius: 2vh;
    box-shadow: 2px 2px rgba(0, 0, 0, 0.1);
}

.aboutDriverInfoBody {
    display: flex;
    flex-direction: column;
    align-self: center;
    height: 28vh;
    width: 48vw;
    padding-top: 1.5vh;
    padding-bottom: 1.5vh;
    padding-left: 2vw;
    padding-right: 2vw;
    font-size: 3vh;
    text-align: center;
    border-bottom-right-radius: 2vh;
    border-bottom-left-radius: 2vh;
    overflow: hidden;
    word-wrap: break-word;
    text-overflow: ellipsis;
}

.aboutDriverImage {
    object-fit: cover;
    object-position: center;
    display: flex;
    align-self: center;
    height: 30vw;
    width: 30vw;
    border-radius: 50%;
}

.aboutDriverModalClose {
    position: absolute;
    background-color: rgba(0, 0, 0, 0);
    font-size: 6vw;
    text-align: center;
    top: 0;
    right: 2vw;
    border: none;
    z-index: 10;
}

.aboutDriverModal {
    background-color: rgba(0, 0, 0, 0);
}
